import axios from "axios";
import { useUserDataContext } from "../Contexts/UserDataContext";
import { useAccountContext, cognitoUserAttributes } from "../Contexts/Account";

export function CheckoutForm() {
  function RedirectToStripe(e: any) {
    e.preventDefault();
    setCheckoutPopupOpen(false);
    setIsLoading(true);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_STRIPE_API_URL!,
      headers: {
        authToken: loggedInUserCognitoIdToken,
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        userId: (userCognitoAttrubutes as cognitoUserAttributes).sub!,
        email: (userCognitoAttrubutes as cognitoUserAttributes).email,
        successUrl:
          process.env.REACT_APP_STRIPE_SUCCESS_URL == null
            ? "www.wheresour.com"
            : process.env.REACT_APP_STRIPE_SUCCESS_URL,
        cancelUrl:
          process.env.REACT_APP_STRIPE_CANCEL_URL == null
            ? "www.wheresour.com"
            : process.env.REACT_APP_STRIPE_CANCEL_URL,
      },
    })
      .then((res) => {
        setIsLoading(false);
        setCheckoutPopupOpen(false);
        console.log(res.data);
        window.location.href = res.data;
      })
      .catch((error) => {
        setIsLoading(false);
        setCheckoutPopupOpen(true);
        alert(
          "Unable to open Stripe at this time. Please try again. If issue persists please contact admin@wheresour.com"
        );
        console.error(error);
      });
  }

  const {
    setCheckoutPopupOpen,
    setIsLoading,
    subscriptionEndDate,
    subscriptionExpired,
    subscriptionStatus,
    subscriptionCancelAtPeriodEnd,
  } = useUserDataContext();
  const { userCognitoAttrubutes, loggedInUserCognitoIdToken } =
    useAccountContext();

  var trialPeriodStatuses = ["trialperiod", "incomplete"];
  return (
    <div>
      {
        //Handle expired Subscription
        subscriptionExpired && (
          <>
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) !=
              -1 &&
              subscriptionEndDate != "" && (
                <div>
                  <p>
                    Our records indicate that your free trial expired on{" "}
                    {subscriptionEndDate}. A subscription is required after the
                    trial period in order to continue using the application. The
                    subscription price is $20.00 every 3 months ($6.67 per
                    month).
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) !=
              -1 &&
              subscriptionEndDate == "" && (
                <div>
                  <p>
                    Our records indicate that your free trial expired. A
                    subscription is required after the trial period in order to
                    continue using the application. The subscription price is
                    $20.00 every 3 months ($6.67 per month).
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) ==
              -1 &&
              subscriptionEndDate != "" && (
                <div>
                  <p>
                    Our records indicate that your subscription expired on{" "}
                    {subscriptionEndDate}. If you wish to continue using the
                    application please click 'Subscribe' and follow the prompts
                    to renew your subscription.
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) ==
              -1 &&
              subscriptionEndDate == "" && (
                <div>
                  <p>
                    Our records indicate that your subscription expired. If you
                    wish to continue using the application please click
                    'Subscribe' and follow the prompts to renew your
                    subscription.
                  </p>
                </div>
              )}
          </>
        )
      }
      {
        //Handle active Subscription
        !subscriptionExpired && (
          <>
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) !=
              -1 &&
              subscriptionEndDate != "" && (
                <div>
                  <p>
                    Your free trial will expire on {subscriptionEndDate}. After
                    that time a subscription is required in order to continue
                    using the application. The subscription price is $20.00
                    every 3 months ($6.67 per month). Click 'Subscribe' to
                    complete your subscription via Stripe.
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) !=
              -1 &&
              subscriptionEndDate == "" && (
                <div>
                  <p>
                    The free trial period is 45 days. After that time a
                    subscription is required in order to continue using the
                    application. The subscription price is $20.00 every 3 months
                    ($6.67 per month). Click 'Subscribe' to complete your
                    subscription via Stripe.
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) ==
              -1 &&
              subscriptionEndDate != "" &&
              subscriptionCancelAtPeriodEnd && (
                <div>
                  <p>
                    Our records indicate that your subscription will expire on{" "}
                    {subscriptionEndDate} and it is not setup to automatically
                    renew. If you wish to continue using the application once
                    the current subscription expires please click 'Subscribe'
                    and follow the prompts to renew your subscription.
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) ==
              -1 &&
              subscriptionEndDate != "" &&
              !subscriptionCancelAtPeriodEnd && (
                <div>
                  <p>
                    Our records indicate that your subscription will expire on{" "}
                    {subscriptionEndDate} and it is setup to automatically
                    renew. Nothing is required at this time for your
                    subscription to renew. If you wish to review your Stripe
                    account associated with this subscription please click
                    'Subscribe' and follow the prompts to manage your
                    subscription.
                  </p>
                </div>
              )}
            {trialPeriodStatuses.indexOf(subscriptionStatus.toLowerCase()) ==
              -1 &&
              subscriptionEndDate == "" && (
                <div>
                  <p>Click subscribe to manage your subscription.</p>
                </div>
              )}
          </>
        )
      }
      <button onClick={RedirectToStripe}>Subscribe With Stripe</button>
      <button onClick={() => setCheckoutPopupOpen(false)}>Cancel</button>
    </div>
  );
}
