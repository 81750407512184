import styled from "styled-components";

export const Button = styled.button`
  border-radius: 30px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, .50);
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  /* padding: 15px 15px; */
  width: 140px;
  height: 30px;
  background-color: ${({ bg }) => bg || "#fff"};
  color: ${({ color }) => color || "#608cdf"};
  margin: 5px;
  &:hover:not(:focus) {
    opacity: 0.9;
    transform: scale(0.98);
  }
`;

export const SubscriptionExpiredButton = styled(Button)`
  background:red;
  color:#ffffff;
`;

export const LoginButton = styled(Button)`
  
`;

export const CardButton = styled(Button)`
  background-color: #fff;
  word-wrap: break-word;
  height: 50px;
  width: 45%;
`;

export const LongerCardButton = styled(CardButton)`
  width:auto;
  min-width: 45%;
  max-width: 90%;
  word-wrap:none;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
`;

export const StyledIS_CardButton = styled(Button)`
  background-color: #fff;
  word-wrap: break-word;
  height: auto;
  min-height:50px;
  width: 40%;
  min-width:100px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50px;
`;

export const AddItemButton = styled(Button)`
  width:auto;
  white-space:nowrap;
`;

export const CardButtonTitle = styled(Button)`
  background-color: #fff;
  word-wrap: none;
  height: 50px;
  width: 70%;
  align-self:center;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
`;

export const ItemSubmissionImageDelete = styled.button`
  position: absolute;
  background-color: rgb(132, 198, 255,.75);
  border-radius: 16px;
  bottom: 5px;
  width: 100px;
  height: 10%;
  box-shadow:none;
  border: none;
  &:hover{
    box-shadow: 0px 0px 2px 2px rgb(121, 190, 250);
    background-color: rgb(132, 198, 255,1);
  }
  &:active{
    background-color: rgba(101, 183, 255, 1);
    box-shadow: 0px 0px 2px 2px rgb(121, 190, 250);
  }
`;

export const InUseButton = styled(Button)`
  width:auto;
  white-space:wrap;
  height:auto;
  min-height:30px;
  align-self:center;
  justify-self:end;
`;